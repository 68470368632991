.modalWrap {
  background: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

.modal {
  background: white;
  border: 1px solid black;
  border-radius: 15px;
  position: fixed;
  z-index: 5;
  margin: 5vh auto;
  top: 0;
  padding: 50px 0;
  width: 80%;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  height: 90vh;
  overflow-y: scroll;
  box-sizing: border-box;
  > form {
    width: 100%;
    align-self: center;
    display: flex;
    box-sizing: border-box;
    padding-top: 20px;
    margin-left: -50px;
    flex-direction: column;
    align-items: center;
  }
}
.crossModal {
  position: absolute;
  color: black;
  font-size: 35px;
  top: 5px;
  right: 10px;
  cursor: pointer;
}
