.root {
  position: relative;
  overflow: hidden;
}
.teamCEOImg {
  will-change: transform;
}

.backGroundRectangleLeft {
  border-top: 680px solid #0f0f0f;
  border-left: 0 solid transparent;
  border-right: 430px solid transparent;
  height: 0;
  width: 430px;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;

  z-index: -1;
  @media (max-width: 1024px) {
    width: 305px;
  }
  @media (max-width: 880px) {
    display: none;
  }
}

.backGroundRectangleRight {
  position: absolute;
  overflow: hidden;
  height: 670px;
  width: 100%;
  top: 680px;

  @media (max-width: 880px) {
    display: none;
  }
  &:after {
    content: "";
    position: absolute;
    width: 1100%;
    height: 1100%;
    right: -473.25%;
    @media (max-width: 1024px) {
      right: -473.25%;
    }
    background: linear-gradient(245.27deg, #0e0d0d -2.34%, #0f0f0f 81.2%);
    transform-origin: 50.6% 0;
    transform: rotate(-120deg);
    z-index: -1;
  }
}
.backGroundRectangleLeftSec {
  position: absolute;
  overflow: hidden;
  height: 1000px;
  margin-top: 1350px;
  width: 100%;
  @media (max-width: 880px) {
    display: none;
  }
  &:after {
    content: "";
    position: absolute;
    width: 1100%;
    height: 1100%;
    right: -495.7%;
    @media (max-width: 1024px) {
      right: -506.5%;
    }
    background: linear-gradient(245.27deg, #0e0d0d -2.34%, #0f0f0f 81.2%);
    transform-origin: 51% 0;
    transform: rotate(120deg);
    z-index: -1;
    overflow: hidden;
  }
}
.section {
  display: flex;
}
.center {
  justify-content: center;
}
.logoBlock {
  margin-top: 75px;
  margin-left: 15%;
  width: 340px;
  @media (max-width: 1250px) {
    margin-left: 10%;
  }
  @media (max-width: 1024px) {
    margin-left: 5%;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 5% auto 0;
    width: 75%;
    align-items: center;
  }

  @media (max-width: 630px) {
    margin: 50px auto 0;
  }
  @media (max-width: 425px) {
    width: 85%;
  }
  > h1 {
    position: absolute;
    top: 5px;
    margin: 0;
    right: 0;
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    color: #ffffff;
  }
}
.description {
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #edf8fc;
    @media (max-width: 980px) {
      font-size: 14px;
    }
  }
  > p:last-child {
    width: 263px;
    @media (max-width: 980px) {
      width: 240px;
    }
    @media (max-width: 880px) {
      width: 200px;
    }
    @media (max-width: 768px) {
      width: 80%;
      margin: 0 auto;
    }
    @media (max-width: 630px) {
      width: 100%;
    }
  }
  > p:first-child {
    margin-bottom: 10px;
    width: 330px;
    @media (max-width: 980px) {
      width: 300px;
    }
    @media (max-width: 880px) {
      width: 270px;
    }
    @media (max-width: 768px) {
      width: 80%;
      margin: 0 auto 10px;
    }
    @media (max-width: 630px) {
      width: 100%;
    }
  }
}

.teamCEO {
  position: absolute;
  width: 475px;
  height: 432px;
  right: 15%;
  top: 5%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1250px) {
    right: 5%;
  }
  @media (max-width: 980px) {
    width: 380px;
  }

  @media (max-width: 768px) {
    position: static;
    margin: 0 auto;
    height: auto;
  }
  @media (max-width: 425px) {
    width: 245px;
  }

  > img {
    width: 315px;
    margin: 30px auto 10px auto;
    display: block;
    @media (max-width: 980px) {
      width: 300px;
      height: auto;
    }
    @media (max-width: 768px) {
      margin: 60px auto 10px auto;
      width: 240px;
    }
  }
  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 40px;
    line-height: 47px;
    text-align: center;
    color: #edf8fc;
    margin: 0;
    display: inline-block;
    @media (max-width: 980px) {
      font-size: 35px;
    }
    @media (max-width: 425px) {
      font-size: 30px;
    }
  }
  > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: #edf8fc;
    margin: 0;
    display: inline-block;
    @media (max-width: 980px) {
      font-size: 20px;
    }
    @media (max-width: 425px) {
      font-size: 18px;
    }
  }
}
.team {
  width: 70%;
  display: flex;
  align-self: center;
  justify-self: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 130px;
  padding-top: 30px;
  @media (max-width: 1250px) {
    width: 80%;
  }
  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 900px) {
    width: 85%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 0;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-content: center;
  }
}
.member {
  // margin: 0 50px 150px;

  margin: 0 calc((100% - 735px) / 4) 150px;
  &:nth-child(3n) {
    margin-right: 0;
  }
  &:nth-child(4n),
  &:first-child {
    margin-left: 0;
  }
  width: 245px;
  // @media (max-width: 1100px) {
  //   margin: 0 20px 100px;
  // }
  @media (max-width: 900px) {
    margin: 60px calc((100% - 490px) / 4) 0 !important;
    &:last-child {
      margin-bottom: 100px !important;
    }
  }

  > img {
    min-width: 200px;
    max-width: 255px;
    min-height: 210px;
    max-height: 210px;
  }
  > h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #edf8fc;
  }
  > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #edf8fc;
  }
}
.leftArr {
  position: absolute;
  top: 230px;
  left: 0;
  @media (max-width: 1024px) {
    display: none;
  }
}
.rigthArr {
  top: 115px;
  right: 0;
  position: absolute;
  @media (max-width: 1024px) {
    display: none;
  }
}
.letterO {
  width: 200px;
  display: block;

  @media (max-width: 630px) {
    margin: 0 auto 0 0;
  }
  @media (min-width: 769px) {
    display: none;
  }
}
