.root {
  border-top: 1px solid #cae5ee;
  height: 100%;
  min-width: 265px;
  a,
  li {
    list-style: none;
    text-transform: none;
    text-decoration: none;
    width: 100%;
    > div > button > span {
      display: none;
    }
  }
  a{
    position: relative;
  }
  a>p {
    display: block;
    min-width: 19px;
    min-height: 19px;
    background: #0500ff;
    padding: 2px;
    margin-right: 15px;
    position: absolute;
    top: 20px;
    text-align: center;
    left: 218px;
  }
}
