.root {
  >h1{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 26px;
    line-height: 30px;
    color: #232323;
  }
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  width: fit-content;
  margin-bottom: 50px;
}