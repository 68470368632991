.root {
  width: 30%;

  z-index: -1;

  @media (max-width: 768px) {
    width: auto;
  }
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  .cardLogo {
    padding: 0;
    display: block;
    margin: 100px auto 0;
    @media (max-width: 768px) {
      margin: 140px auto 0;
    }
    @media (max-width: 425px) {
      margin: 120px auto 0;
    }
  }

  .cardText {
    padding: 0;
    width: 100%;
    margin-top: 30px;
    > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: #edf8fc;
      &:first-child {
        margin-bottom: 10px;
      }
    }

    // pay attention -- absolute height
    height: fit-content;
    box-sizing: border-box;

    @media (max-width: 768px) {
      width: 100%;
      margin: 30px auto 40px;
    }
    // @media (max-width: 630px) {
    //   width: 80%;
    //   margin: 0 auto;
    // }
    @media (max-width: 425px) {
      width: 100%;
      margin: 0 auto;
      padding: 30px 0 40px;
    }
  }
}
