.root {
  overflow: hidden;
  position: relative;
  display: flex;
  min-height: calc(100vh - 300px);
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
}

.trapezoid {
  position: absolute;
  left: 0;
  top: 0;
}
// copypasted, consider making a component
.backGroundRectangleLeft {
  border-bottom: 100vh solid #0f0f0f;
  border-left: 0 solid transparent;
  border-right: 420px solid transparent;
  height: 0;
  width: 55%;
  position: absolute;
  left: 0;
  z-index: -1;

  @media (max-width: 930px) {
    display: none;
  }
}

.content {
  margin-top: 60px;
  margin-bottom: 20px;
  width: 700px;
  margin-left: -75px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
  }

  > p {
    color: #ffffff;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 15px;
    z-index: 100;
    @media (max-width: 930px) {
      max-width: 65%;
    }
    @media (max-width: 768px) {
      max-width: 100%;
    }
  }
  > p:last-child {
    width: 650px;
  }
  > p:nth-last-child(2) {
    width: 600px;
  }
  > p:nth-last-child(3) {
    width: 560px;
  }
  > p:nth-last-child(4) {
    width: 495px;
  }
}

.legalBasis {
  @media (max-width: 768px) {
    margin-top: 80px;
  }
}

.title {
  color: #ffffff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;

  @media (max-width: 768px) {
    display: none;
  }
}

.logoBlock {
  top: 130px;
  right: 210px;

  h1 {
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    color: #ffffff;
    margin-left: 90px;
    display: block;
    margin-top: -35px;
  }
}
.legalLogo {
  right: 215px;
  > h1 {
    margin-top: -190px;
    margin-left: 50px;
  }
}
.leftArr {
  position: absolute;
  top: 230px;
  left: 0;

  @media (max-width: 768px) {
    display: none;
  }
}
.rigthArr {
  top: 115px;
  right: 0;
  position: absolute;

  @media (max-width: 768px) {
    display: none;
  }
}

@media (max-width: 768px) {
  .content {
    width: 100%;
    p {
      width: 100% !important;
    }
  }
}
