.root {
  opacity: 0;
  @media (max-width: 768px) {
    width: 100vw;
    position: relative;
    z-index: 100;
    opacity: 1;
  }
}

.contactUsLetterAnim {
  width: 100vw;
  height: 100%;
  left: -50px;
  top: -60px;
  z-index: -1;
  height: 800px;
  position: absolute;
  @media (max-width: 1024px) {
    top: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}
.contactUsLetterGroup {
  transform: translateX(calc(100vw - 1180px)) translateY(32%);
  @media (max-width: 1250px) {
    transform: translateX(calc(100vw - 1040px)) translateY(32%);
  }
  @media (max-width: 1100px) {
    transform: translateX(calc(100vw - 900px)) translateY(32%);
  }
  @media (max-width: 980px) {
    transform: translateX(calc(100vw - 850px)) translateY(32%);
  }
  @media (max-width: 850px) {
    transform: translateX(calc(100vw - 760px)) translateY(32%);
  }
  @media (max-width: 768px) {
    transform: none;
  }
  @media (min-width: 1800px) {
    transform: translateX(calc(70vw - 760px)) translateY(32%);
    
  }
}
.title {
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 350;
  font-size: 50px;
  line-height: 50px;
  text-align: right;
  color: #ffffff;
  margin: 0;
  margin-top: 220px;
  @media (max-width: 768px) {
    margin-top: 130px;
    text-align: center;
  }
  @media (min-width: 1500px) {
    position: absolute;
    left: 40%;
  }
}
