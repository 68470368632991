.root {
  display: flex;
  flex-direction: column;
}
.accordion {
  background: none;
  color: #fff;
  cursor: pointer;
  padding: 18px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  &:not(first-child) {
    border-top: 1px solid rgba(202, 229, 238, 0.2);
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-left: 50px;
    text-decoration: none;
    text-transform: none;
    width: 80%;
    text-align: start;
  }
}

.accordionContent {
  overflow: hidden;
  transition: max-height 0.6s ease;
}
span.menuItem{
  margin-right: 25px;
}
span.menuItem:after {
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-bottom: none;
  border-top-color: #fff;
  content: "";
  vertical-align: middle;
  display: inline-block;
  position: relative;
  right: 0;
}
.accordionContent > ul {
  margin-left: 60px;
  list-style: none;

  > li {
    border-top: 1px solid rgba(202, 229, 238, 0.05);
    padding-left: 10px;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    > a {
      text-decoration: none;
      text-transform: none;
    }

    > p {
      display: block;
      min-width: 19px;
      min-height: 19px;
      background: #0500ff;
      padding: 2px;
      margin-right: 35px;
      text-align: center;
    }
  }
}
/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(-180deg);
}