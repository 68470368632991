.root {
  margin-top: 180px;
  text-align: center;
}
.benefitsLetterAnim {
  position: absolute;
  width: 100vw;
  left: 0;
  top: -10px;
  transform-origin: center;
  height: 800px;
  z-index: -1;
  opacity: 0;
  @media (max-width: 1024px) {
    top: -10px;
  }
  @media (max-width: 768px) {
    display: none;
    opacity: 1;
  }
}
.benefitsLetterGroup {
  transform: translateY(calc(38% - 110px)) translateX(calc(50% - 222px));
  path {
    will-change: transform;
  }
  @media (max-width: 768px) {
    transform: none;
  }
}
.benefitsTitle {
  text-transform: uppercase;
  text-align: center;
  font-family: Bebas Neue, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  display: inline-block;
  margin: 0;
}

.benefitsTitle:first-of-type {
  transform: translateX(-2000px);
  @media (max-width: 768px) {
    transform: none;
    position: relative;
    z-index: 100;
  }
}

.benefitsTitle:last-of-type {
  transform: translateX(2000px);
  @media (max-width: 768px) {
    transform: none;
    position: relative;
    z-index: 100;
  }
}
