.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  margin: 0 0 15px 0;
  letter-spacing: 0.05em;
  color: rgba(237, 248, 252, 0.9);
  transform: translateY(125px);
  text-align: center;

  @media (max-width: 768px) {
    transform: translateY(0px);
    font-size: 20px;
    line-height: 26px;
  }
}

.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 10px 0;
  transform: translateX(1000px);
  text-align: justify;
  color: #edf8fc;
  @media (max-width: 768px) {
    transform: none;
  }
}

.text:last-of-type {
  margin: 0;
}

.btn {
  transform: translateY(1000px);
}

.aboutUs {
  width: 551px;
  position: relative;
  margin-left: 132px;
  @media (max-width: 1024px) {
    margin-left: 100px;
  }
  @media (max-width: 768px) {
    margin: 60px auto 220px;
    width: 100%;
  }
  @media (max-width: 425px) {
    margin: 60px auto 140px;
  }
}
