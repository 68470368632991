.root {
  position: relative;
  padding-top: 300px;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 90px;
  }
}

.root .leftImg {
  position: absolute;
  padding: 0;
  left: 0;
  top: 0px;
  z-index: 50;
  @media (max-width: 630px) {
    display: none;
  }
}

.capabilitiesBack {
  position: absolute;
  height: 600px;
  width: 50px;
  top: -305px;
  transform: rotate(-25deg) translateX(0px) !important;

  &:after {
    content: "";
    position: absolute;
    top: -130px;
    bottom: 0px;
    left: -500px;
    right: 0px;
    transform: skewX(37deg);
    background: linear-gradient(201.19deg, #3f3f3f 23.15%, #0f0f0f 100.77%);
  }
  @media (max-width: 1250px) {
    left: -100px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
}

.capabilitiesBackTrap {
  border-top: 110px solid rgba(15, 15, 15, 0.7);
  border-left: 0 solid transparent;
  border-right: 207px solid transparent;
  height: 0;
  width: 240px;
  position: absolute;
  left: 0;
  top: 255px;
  transform: translate3d(-400px, 100px, 0);
  @media (max-width: 1250px) {
    left: -100px;
  }
}

.capabilities {
  width: 230px;
  left: 0;
  top: 360px;
  position: absolute;
  @media (max-width: 1024px) {
    display: none;
  }
}

.capabilitiesTitle {
  text-transform: uppercase;
  font-family: Bebas Neue Book, serif;
  font-style: normal;
  font-weight: 300;
  font-size: 45px;
  color: #edf8fc;
  transform: translateX(-400px);
  margin: 123px 0 0 152px;
  @media (max-width: 1250px) {
    margin-left: 50px;
  }
}

.capabilitiesText {
  margin: 90px 0 0 152px;

  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  min-width: 200px;
  color: #edf8fc;

  transform: translate3d(-400px, 100px, 0);
  @media (max-width: 1250px) {
    margin-left: 50px;
  }
}

.container {
  width: calc(100% - 30% - 15%);
  margin: 100px auto 0 35%;

 
  @media (max-width: 1024px) {
    padding: 0 5%;
    width: auto;
    margin: 0;
    margin-top: 95px;
  }
  @media (max-width: 768px) {
    margin: 60px auto 0;
    padding: 0 15%;
    width: calc(100% - 30%);
  }
  @media (max-width: 600px) {
    padding: 0 4%;
    width: calc(100% - 8%);
  }
}



.iconCards {
  display: flex;
  opacity: 0;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-bottom: 80px;
  @media (max-width: 1024px) {
    padding: 0 7%;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 0;
    opacity: 1;
    padding: 0;
    width: 100%;
    justify-content: flex-start;
  }
  @media (max-width: 425px) {
    flex-wrap: wrap;
    margin-bottom: 0;
    opacity: 1;

    justify-content: center;
  }
}

.iconCard:last-of-type {
  margin-left: 0;
  margin-right: 0;
}

.iconCard {
  width: 130px;
  // margin: 0 105px 0 0;
  &:first-child {
    margin-right: calc((100% - 520px) / 3);
    margin-left: 0;
  }
  &:nth-child(2),
  &:nth-child(3) {
    margin-right: calc((100% - 520px) / 3);
    margin-left: 0;
  }
  &:nth-child(4) {
    margin-left: 0;
    margin-right: 0;
  }
  &:last-child {
    margin-top: 50px;
    margin-left: calc((100% - 390px) / 4);
    margin-right: calc((100% - 390px) / 4);
  }
  &:nth-child(6),
  &:nth-child(5) {
    margin-top: 50px;
    margin-left: calc((100% - 390px) / 4);
    margin-right: 0;
  }
  img {
    display: block;
    padding: 0;
    margin: 0 auto;
    @media (max-width: 425px) {
      width: 160px;
    }
  }
  @media (max-width: 1250px) {
    margin-right: 0;
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    &:first-child {
      margin-right: calc((100% - 520px) / 3);
      margin-left: 0;
    }
    &:nth-child(2),
    &:nth-child(3) {
      margin-right: calc((100% - 520px) / 3);
      margin-left: 0;
    }
    &:nth-child(4) {
      margin-left: 0;
      margin-right: 0;
    }
    &:last-child {
      margin-top: 50px;
      margin-left: calc((100% - 390px) / 4);
      margin-right: calc((100% - 390px) / 4);
    }
    &:nth-child(6),
    &:nth-child(5) {
      margin-top: 50px;
      margin-left: calc((100% - 390px) / 4);
      margin-right: 0;
    }
  }
  @media (max-width: 768px) {
    max-width: 130px;
    margin-bottom: 50px;
    margin-top: 0 !important;
    &:first-child,
    &:nth-last-child(2) {
      margin-right: calc((50% - 130px) / 3);
      margin-left: calc((50% - 130px) - ((50% - 130px) / 3));
    }
    &:nth-child(2),
    &:last-child {
      margin-left: calc((50% - 130px) / 3);
      margin-right: calc((50% - 130px) - ((50% - 130px) / 3));
    }
    &:nth-child(3),
    &:nth-child(5) {
      margin-left: 0;
      margin-right: 0;
    }
    &:nth-child(4) {
      margin-left: calc((100% - 390px) / 2);
      margin-right: calc((100% - 390px) / 2);
    }
  }
  @media (max-width: 425px) {
    width: 100%;
    max-width: 100%;
    margin: 0 auto 40px !important;
  }
}

.cardTitle {
  margin: 10px 0 0;
  text-align: center;
  letter-spacing: 0.03em;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  line-height: 20px;
  color: #edf8fc;

  text-transform: uppercase;
  @media (max-width: 768px) {
    max-width: 100%;
    margin: 10px auto 0;
    font-size: 16px;
  }
  @media (max-width: 425px) {
    max-width: 100%;
  }
}

.button {
  transform: translateY(1000px);
}
.letterW {
  margin: -141px auto 0;
  display: block;
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 425px) {
    max-width: 100%;
  }
}
