@import "../../../../../../node_modules/slick-carousel/slick/slick.scss";
.root {
  margin: 0 auto;
  position: relative;
  padding: 180px 150px 80px;
  overflow: hidden;
  // @media (max-width: 1250px) {
  //   padding: 150px 100px 0;
  // }
  @media (max-width: 1024px) {
    padding: 180px 5% 0;
  }
  @media (max-width: 768px){
    padding: 220px 15% 0;
  }
  @media (max-width: 425px) {
    padding: 150px 10px 0;
  }
  .rightImg {
    position: absolute;
    padding: 0;
    right: 0;
    top: 0;
    @media (max-width: 630px) {
      display: none;
    }
  }

  .benefitsCards {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    @media (max-width: 768px) {
      justify-content: center;
      margin-top: 130px;
    }
    > * {
      will-change: transform;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
}
.slider {
  @media (min-width: 769px) {
    display: none !important;
  }
}
.letterO {
  display: block;
  margin: -180px 130px;
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 425px) {
    margin: -180px 10px;
  }
}
div[class*="slick-slider"] {
  min-height: 350px;
  > div[class*="slick-list"] {
    padding: 0 !important;
  }
}
.sliderDots {
  display: flex !important;
  justify-content: center;

  > li[class*="slick-active"] > button {
    background: white;
    box-shadow: none;
  }
  > li > button {
    width: 15px;
    height: 15px;
    cursor: pointer;
    background: #040404;
    outline: none;
    border: none;
    box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.5);
    margin-left: 15px;
  }
  > li:first-child > button {
    margin-left: 0;
  }
}
