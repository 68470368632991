@import "../../components/scss/tables.scss";
@import "../../components/scss/tabs.scss";
@import "../../components/scss/textareas.scss";

.flex {
  display: flex;
}

.typeContent {
  width: 310px;
  margin-top: 25px;
  &:first-child {
    margin-right: 50px;
  }
}
.upload {
  > div {
    width: 50%;
    margin: 0 auto;
  }
}
.flexContent {
  display: flex;
  flex-wrap: wrap;
}
.title {
  margin-top: 40px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(23, 23, 23, 0.2);
  > h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #232323;
  }
}
.subTitle {
  margin-top: 40px;
  padding-bottom: 5px;
  > h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #232323;
  }
}

.leaderItem {
  margin-top: 25px;
  max-width: 360px;
  &:first-child {
    margin-right: 50px;
  }
  textarea {
    width: 360px;
  }
}

.modalWrap {
  background: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

.modal {
  background: white;
  border: 1px solid black;
  position: fixed;
  z-index: 5;
  margin: 10% auto;
  top: 0;
  width: 60%;
  height: 90vh !important;
  overflow-y: scroll;
  padding: 16px;
  left: 0;
  right: 0;
  > form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

