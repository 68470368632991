.root {
  width: 264px;
  height: 220.8px;
  @media (max-width: 768px) {
    width: auto;
    height: auto;
  }
}

.title {
  position: absolute;
  left: 400px;
  width: 167px;
  height: 100px;
  z-index: 2;
  @media (max-width: 1250px) {
    left: 25%;
  }
  @media (max-width: 768px) {
    top: 5%;
    left: 55%;
  }
  @media (max-width: 630px) {
    margin-top: 135px;
    margin-left: 15%;
    top: 0;
    left: 25%;
  }
}

.title > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: inline-block;
}

.letterAnim {
  height: 100vh;
  width: 100vw;
  left: -30px;
  top: -60px;
  position: absolute;
  @media (max-width: 1250px) {
    left: 0;
  }
  @media (max-width: 1024px) {
    left: -65px;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.letterTop,
.letterBottom {
  will-change: transform;
}

.letterPos {
  transform: translateX(248px) translateY(130px);
  @media (max-width: 1250px) {
    transform: translateX(10%) translateY(130px);
  }
}
