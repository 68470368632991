.root {
  display: flex;
  position: relative;
  overflow: hidden;

  .leftImg {
    position: absolute;
    bottom: 0;
    padding: 0;
    z-index: -2;
    @media (max-width: 630px) {
      display: none;
    }
  }

  .rightImg {
    position: absolute;
    top: 55px;
    right: 0;
    padding: 0;
    z-index: -2;
    @media (max-width: 630px) {
      display: none;
    }
  }

  // Весь контент в секции Лого выровнен по центру (по горизонтали и по вертикали)
  .mainContent {
    margin: 160px auto 100px;
    @media (max-width: 768px) {
      margin: 190px auto 240px;
      padding: 0 10%;
    }
    @media (max-width: 425px) {
      margin: 90px auto 140px;
      padding: 0;
    }
  }

  .mainPart {
    width: 658px;
    display: flex;
    align-items: center;
    margin: -9px 0 0 0px;
    @media (max-width: 980px) {
      flex-direction: column;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    .title {
      margin: 21px 0 0 11px;
      min-width: 530px;
      @media (max-width: 768px) {
        width: 100%;
        min-width: 0;
        margin: 0;
        margin-top: -180px;
      }
      .magisoft {
        font-size: 110px;
        font-family: Gotham Pro, serif !important;
        opacity: 0;
        width: 110%;
        text-align: center;
        letter-spacing: 4px;
        @media (max-width: 768px) {
          font-size: 110px;
          text-align: center;
          width: 100%;
          opacity: 1;
        }
        @media (max-width: 630px) {
          font-size: 75px;
        }
        @media (max-width: 425px) {
          margin-top: 70px;
          font-size: 57px;
        }
      }

      .magisoft span {
        font-family: Gotham Pro, serif !important;

        display: inline-block;
        line-height: 1em;
        transform: translateX(2000px);
        will-change: transform;
      }
      .solutions {
        font-size: 36px;
        font-family: ISOCT2, serif;
        font-weight: bold;
        margin: -20px 0 0 285px;
        opacity: 0;
        letter-spacing: -1rem;
        will-change: letter-spacing;
        @media (max-width: 980px) {
          // margin: -17px 0 0 245px;
          // font-size: 35px;
        }
        @media (max-width: 768px) {
          font-size: 36px;
          margin: -17px 0 0 0;
          text-align: end;
          opacity: 1;
          letter-spacing: normal;
        }
        @media (max-width: 630px) {
          font-size: 25px;
        }
        @media (max-width: 425px) {
          width: 90%;
          font-size: 22px;
          margin: 0 25px;
        }
      }
    }
  }

  .descr {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: rgba(237, 248, 252, 0.9);
    margin: 70px 0 0 0px;
    transform: translateY(60vh);
    will-change: transform;
    @media (max-width: 768px) {
      transform: none;
      width: 100%;
      margin: 100px auto 0;
      font-size: 19px;
    }
  }

  .button {
    transform: translateY(60vh);
  }
}

.mainLogo {
  width: 300px;
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 425px) {
    width: 180px;
  }
}

