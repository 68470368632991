.root {
  position: absolute;
  right: 123px;
  top: 56px;
  width: 232px;
  height: 100%;
}

.letterAnim {
  height: 100%;
  width: 150%;
  left: 0;
  top: 50px;
  position: absolute;
}

.letterTop,
.letterBottom {
  will-change: transform;
  @media (max-width: 1024px) {
    transform: translateY(60px) translateX(130px) !important;
  }
  @media (max-width: 630px) {
      display: none;
  }
}

.title {
  position: absolute;
  top: 200px;
  left: 80px;
  width: 167px;
  height: 100px;
  z-index: 2;

  @media (max-width: 1024px) {
    top: 245px;
    left: 160px;
  }
  
}

.title > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: block;
}
