.root {
  width: 100vw;
  left: 0;
  top: 0;
  @media (max-width: 425px) {
    width: 100%;
  }
}
.doBestTitle {
  text-transform: uppercase;
  text-align: center;
  font-family: Bebas Neue, serif;
  font-style: normal;
  font-weight: 350;
  font-size: 70px;
  transform: translateY(125px);
  @media (max-width: 768px) {
    transform: none;
    font-size: 50px;
    position: relative;
    z-index: 100;
  }
  margin: 0;
}

.doBestLetterAnim {
  position: absolute;
  width: 100%;
  height: 244px;
  margin-top: -240px;
  z-index: -1;
  opacity: 0;
  @media (max-width: 1250px) {
    width: 110%;
    right: 100px;
  }
  @media (max-width: 1000px) {
    width: 127%;
    right: 50px;
  }
  @media (max-width: 768px) {
    display: none;
    opacity: 1;
  }
}

// #do-best-part-1, #do-best-part-2 {
//     transform: translateX(50%);
// }
