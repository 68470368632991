.root {
  height: 75px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  > .img {
    margin-left: 80px;
    align-self: center;
  }
  > h2,
  h3 {
    font-size: 16px;
    line-height: 19px;
  }
  > h3 {
      width: 100%;
      text-align: center;
      position: absolute;
  }
}

.logOut{
  color: white;
  position: absolute;
  right:10%;
  border: 1px solid white;
  background: black;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}