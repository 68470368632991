.root {
  display: flex;
  flex-direction: column;
}
.accordion {
  background: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;

  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    margin-left: 50px;
    text-decoration: none;
    text-transform: none;
    width: 80%;
    text-align: start;
  }
}

.accordionContent {
  overflow: hidden;
  transition: max-height 0.6s ease;
}

.flex {
  display: flex;
}
.spaceBetween {
  justify-content: space-between;
}
.cvItem {
  border-top: 1px solid rgba(23, 23, 23, 0.2);
  margin-top: 10px;
  padding-top: 20px;
  width: 90%;
}
.cvInfo {
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 10px;
  }
}
span.infoTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 12px;
  color: #5a5a5a;
  margin-right: 10px;
  align-self: flex-end;
  margin-bottom: 3px;
  &.start {
    align-self: flex-start;
  }
}
p.infoName {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-self: flex-end;
  color: #5a5a5a;
}
p.infoEmail {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #3531ff;
  align-self: flex-start;
  text-align: start;
  max-width: 212px;
  word-wrap: break-word;
}
a.infoCVfile {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-decoration-line: underline;
  color: #5a5a5a;

  > img {
    margin-left: 5px;
    vertical-align: middle;
  }
}
.cvIcons {
  display: flex;
  justify-content: flex-end;
  width: 40px;
  align-self: flex-start;
}
div.accordion__text {
  margin-top: 20px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #5a5a5a;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(-180deg);
}
.delete {
  margin-left: 10px;
}
