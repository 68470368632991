.navbarRoot {
  min-width: 60%;
  @media (max-width: 768px) {
    display: none;
  }

  ul,
  li {
    padding: auto;
    list-style: none;
  }
  ul {
    display: flex;
    justify-content: center;
  }
  li:not(:first-child) {
    margin-left: 50px;

    @media (max-width: 1024px) {
      margin-left: 5%;
    }
  }
  .menu {
    // justify-content: space-between;
    display: flex;
    color: white;
    align-items: center;
    height: 60px;
  }

  a {
    text-decoration: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #a7a7a7;
    display: flex;
    white-space: nowrap;
    line-height: 50px;
  }

  a:first-child {
    margin: 0;
  }
  a:hover,
  a:focus-within {
    color: #ffffff;
  }
}
span.menuItem:after {
  width: 0;
  height: 0;
  border: 0.313em solid transparent;
  border-bottom: none;
  border-top-color: #a7a7a7;
  content: "";
  vertical-align: middle;
  display: inline-block;
  position: relative;
  right: -0.313em;
}
.dropdown {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  display: flex;
  transition: all 0.5s ease;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  height: 50px;
  background: #edf8fc;
  z-index: 3;
  a,
  a:hover,
  a:focus-within {
    color: black;
  }
}
.menu li:hover > a.head {
  color: #ffffff;
}
.menu li:hover > .dropdown {
  visibility: visible;
  opacity: 1;
}
.menu li:hover > a > .menuItem:after {
  border-top-color: #fff;
}

.dropdown > a {
  color: #0c0b0b;
  clear: both;
  padding-left: 50px;
  cursor: pointer;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  &:first-child {
    padding-left: 0;
  }
}
