@import "../../components/scss/tables.scss";
@import "../../components/scss/tabs.scss";
@import "../../components/scss/textareas.scss";

.flex {
  display: flex;
}

.typeContent {
  width: 310px;
  margin-top: 25px;
  &:first-child {
    margin-right: 50px;
  }
}
.flexContent {
  display: flex;
  flex-wrap: wrap;
}

.subTitle {
  margin-top: 40px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(35, 35, 35, 0.2);
  > h4, h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #232323;
  }
  
}

.leaderItem {
  margin-top: 25px;
  max-width: 360px;
  &:first-child {
    margin-right: 50px;
  }
  
}
