.root {
  background: #040404;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
}
.flex {
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.contactForm {
  width: auto;
}
.logoBlock {
  transform: skewX(-10deg);
  background: linear-gradient(255.63deg, #0e0d0d 14.01%, #0f0f0f 93.19%);
  width: 730px;
  position: relative;
  left: -100px;
  height: 740px;

  @media (max-width: 1240px) {
    left: -200px;
  }

  @media (max-width: 1100px) {
    left: -320px;
  }

  @media (max-width: 768px) {
    left: -100px;
    height: 250px;
  }
  > .logoBlockContent {
    left: 100px;
    position: relative;
    transform: skewX(10deg);

    @media (max-width: 768px) {
      position: absolute;
      top: 30px;
      left: 42%;
    }

    @media (max-width: 575px) {
      left: 37%;
    }

    @media (max-width: 425px) {
      left: 33%;
    }

    @media (max-width: 375px) {
      left: 29%;
    }

    .contactUsImg {
      position: absolute;
      left: 180px;
      top: 130px;

      @media (max-width: 768px) {
        display: none;
      }
    }
    > h1 {
      font-family: Bebas Neue;
      font-style: normal;
      font-weight: 300;
      font-size: 50px;
      line-height: 50px;
      text-align: center;
      color: #ffffff;
      position: absolute;
      left: 280px;
      top: 255px;
      width: 180px;
      margin: 0;

      @media (max-width: 768px) {
        left: 100px;
        top: 80px;
      }

      @media (max-width: 375px) {
        left: 80px;
      }

      @media (max-width: 325px) {
        left: 75px;
      }
    }
  }

  @media (max-width: 768px) {
    background: none;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.infoBlock {
  width: 275px;
  position: absolute;
  top: 450px;
  left: 180px;
  .values {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #edf8fc;
  }
  > div.infoContent {
    display: flex;
    >div{
      margin: 5px 0;
    }
    > p {
      margin: 5px 0;
    }

    > p.headers {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #a7a7a7;
      text-align: end;
      margin-right: 10px;
      display: block;
      text-align: end;
      width: 65px;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.leftArr {
  position: absolute;
  top: 230px;
  left: 0;
  z-index: 10;

  @media (max-width: 1100px) {
    display: none;
  }
}
.rigthArr {
  top: 115px;
  right: 0;
  position: absolute;

  @media (max-width: 1300px) {
    display: none;
  }
}
.downloads {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 120px 0 180px;

  @media (max-width: 768px) {
    margin-top: 60px;
    padding-bottom: 90px;
  }
  > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #edf8fc;
    text-align: center;
    width: 700px;
    margin-bottom: 30px;

    @media (max-width: 768px) {
      width: 90%;
      font-size: 20px;
      text-align: center;
      padding-bottom: 20px;
    }
  }
  > div.downloadsButtons {
      width: 630px;
      display: flex;
      justify-content: space-around;
    > a {
      border: none;
      padding: 0;
      background: none;
      cursor: pointer;
      outline: none;
      
      @media (max-width: 375px) {
        width: 100%;
        margin: 0 auto;
      }

      img {
        @media (max-width: 375px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
      align-items: center;

    }
  }
}

.contactUsMobileImg {
  display: none;
  position: absolute;

  @media (max-width: 768px) {
    display: block;
  }
}
