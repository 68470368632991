.root {
  display: flex;
  padding: 60px;
  overflow: hidden;
  position: relative;
  min-height: calc(100vh - 60px);
  box-sizing: border-box;
  background-color: #0f0f0f;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 60px 40px;
  }
}

.backGroundRectangleLeft {
  border-top: calc(100vh - 60px) solid #0b0b0b;
  border-right: 0 solid transparent;
  border-left: 430px solid transparent;
  height: 0;
  width: 55%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  @media (max-width: 768px) {
    display: none;
  }
}

.slider {
  width: 85%;
  min-height: 100%;
  z-index: 1;
  @media (max-width: 768px) {
    order: 2;
    width: 90%;
    margin: 0 auto;
  }
}

.leftArrows {
  position: absolute;
  top: 230px;
  left: 0;
  @media (max-width: 1024px) {
    display: none;
  }
}

.techStackLogo {
  min-height: 230px;
  min-width: 240px;
  height: 100%;

  margin: 0 auto;
  width: 40%;

  z-index: 3;
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: 768px) {
    order: 1;
    margin: 0 auto 40px;
    position: relative;
    height: auto;
  }
}

.more {
  @media (min-width: 769px) {
    display: none;
  }
}
