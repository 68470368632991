.root {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  // min-height: 60vh !important;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.root .rightImg {
  position: absolute;
  z-index: -5;
  right: 0;
  bottom: 120px;
  @media (max-width: 630px) {
    display: none;
  }
}

.container {
  margin: 100px auto;
  height: 246px;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    padding: 0 5%;
    width: 85%;
    height: auto;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    height: auto;
    margin: 0 auto;
    padding: 0 15%;
  }
  @media (max-width: 425px) {
    width: 100%;
    padding: 0 0%;

  }
}

.letterA {
  display: block;
  margin: 0 auto;
  @media (min-width: 769px) {
    display: none;
  }
}
