.root {
  position: relative;
  display: flex;
  overflow: hidden;
  color: #edf8fc;
  justify-content: flex-end;
  @media (max-width: 630px) {
    flex-direction: column;
    align-items: center;
  }
}

ul,
li {
  list-style: none;
}

.logoBlock {
  // flex-basis: 540px;
  height: 670px;
  width: 40%;
  // position: relative;
  background-repeat: no-repeat;
  background-image: url("../../../assets/images/careerLogoBack.png");
  will-change: transform;
  transform: translateX(-540px);
  position: fixed;
  left: 0;
  top: 0;

  @media (max-width: 630px) {
    display: none;
    transform: translateX(0);
  }
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 0 0 26px 0;
  font-size: 24px;
  line-height: 30px;
  > span {
    font-weight: 300;
    font-family: sans-serif;
  }
}

.content {
  display: flex;
  flex-direction: column;
  // flex-grow: 1;
  // margin: 70px 0 150px 100px;
  width: 50%;
  margin: 5% 2% 10% 5%;
  @media (max-width: 1100px) {
    width: 53%;
    margin: 5% 2% 10% 2%;
  }
  @media (max-width: 630px) {
    width: 90%;
    margin: 5% auto;
  }
}

.subtitle {
  &:not(:first-child) {
    margin-top: 40px;
  }
}

.description {
  width: 495px;
  margin-top: 10px;
  margin-bottom: 30px;
  &:before {
    border: none;
    left: 0;
    list-style: none;
  }
}

.listItem {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  vertical-align: middle;
  margin-left: 10%;

  &:before {
    content: "";
    border-color: transparent #fff;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 0.9em;
    position: relative;
  }
}

.subtitleText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  display: inline-block;
}

.cvFormContainer {
  margin: 0 auto 0 0;
  @media (max-width: 630px) {
    margin: 0;
  }
}

.vacancyBlock {
  margin-top: 10%;
  &:first-child {
    margin-top: 0;
  }
}
.mobileLogo {
  position: relative;
  margin: 30px auto 0 25%;
  width: 80%;
  @media (max-width: 425px) {
    margin: 30px auto 0 10%;
    width: 90%;
  }
  > h2 > span {
    position: absolute;
    font-family: Bebas Neue !important;
    font-weight: 300 !important;
    font-style: normal !important;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
    color: #ffffff;
    text-transform: uppercase;
    &:first-child {
      top: 40%;
    }
    &:last-child {
      top: 55%;
    }
    left: 20%;
  }
  @media (min-width: 631px) {
    display: none;
  }
}
.mobileMore {
  // display: flex;
  // justify-content: flex-end;
  margin-top: 20px;
  padding-bottom: 10px;
  @media (max-width: 630px) {
    border-bottom: 1px solid rgba(202, 229, 238, 0.2);
  }
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #0500ff;
    text-align: end;

    @media (min-width: 631px) {
      display: none;
    }
  }
}
.moreInfoBlock {
  @media (max-width: 631px) {
    display: none;
  }
}


.listItem, .description, .subtitleText, .title, .cvFormContainer {
  transform: translateX(100vw);
  @media (max-width: 631px) {
    transform: translateX(0);
  }
}