.root {
  display: flex;
  padding: 30px;
  min-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    padding: 30px 20px;
  }
}

.logoBlock {
  margin: 60px auto 30px;
  min-height: 330px;
  min-width: 164px;
  position: relative;
  width: 25%;
  z-index: 2;

  @media (max-width: 630px) {
    margin: 60px auto 30px 20%;
    min-height: 0;
  }
  @media (max-width: 425px) {
    margin: 20px auto 30px 10px;
  }
}

.sliderBlock {
  min-height: 100%;
  width: 60%;
  // flex: 1 1;
  margin-left: 5%;
  flex-grow: 1;
  flex-shrink: 1;
  margin-top: 40px;
  margin-bottom: 50px;
  will-change: transform;
  //
  @media (max-width: 1024px) {
    margin-left: -5%;
  }
  @media (max-width: 768px) {
    margin-bottom: 90px;
    height: fit-content;
    margin: 0 calc((10% + 50px - 30px));
    margin-bottom: 150px;
    width: calc(100% - (20% + 100px - 60px));
  }
  @media (max-width: 630px) {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 90px;
  }
  > div {
    height: 555px;
    align-items: center;
    display: flex;
    @media (max-width: 768px) {
      height: inherit;
    }
    > button {
      @media (max-width: 768px) {
        display: none !important;
      }
    }
    > ul {
      @media (max-width: 768px) {
        max-width: 30%;
        bottom: -70px;
        left: calc(50% - 15%);
        > li {
          width: 8px;
          height: 8px;
          box-shadow: 0px 0px 7px rgba(255, 255, 255, 0.5);
        }
      }
    }
    > div {
      width: 100%;
    }
  }
}

.trap {
  border-top: 100vh solid rgb(15, 15, 15);
  border-left: 0 solid transparent;
  border-right: 32vw solid transparent;
  height: 0;
  width: 4vw;
  position: absolute;
  left: 0;
  top: 60px;
  z-index: 1;
  will-change: transform;
  @media (max-width: 1024px) {
    width: 0;
  }
  @media (max-width: 940px) {
    display: none;
  }
}

.letterP {
  @media (min-width: 631px) {
    display: none;
  }
}
