// override of react-slick default styles
@import "../../../../node_modules/slick-carousel/slick/slick.css";
@import "../../../../node_modules/slick-carousel/slick/slick-theme.css";

li.slick-active  a, li.slick-active  span{
    opacity: .75;
    background: white;
}
.slick-dots li{
  display: flex;
  justify-content: center;
}

.slick-dots {
    bottom: -35px;
    max-width: 20%;
    left: calc(50% - 10%);
    display: flex!important;
    justify-content: space-between;
    @media (max-width: 768px) {
        max-width: 60%;
        left: calc(50% - 30%);
      }
}
.slick-prev, .slick-next {
    bottom: -40px!important;
    top: auto;
    z-index: 10;
    background: auto;
    &:before{
        display: none;
    }
  
    
}
.slick-prev, .slick-prev:hover, .slick-prev:focus {
    left: 35%;
    background-image: url('./assets/Rectangle1.svg');
    background-size: contain;
    @media (max-width: 768px) {
        left: 10%;
      }
}
.slick-next, .slick-next:hover, .slick-next:focus {
    right: 35%;
    background-image: url('./assets/Rectangle2.svg');
    background-size: contain;
    @media (max-width: 768px) {
        right: 10%;
      }
}


.slider-bullet {
    width: 8px;
    height: 8px;
    display: block;
    background: black;
    cursor: pointer;
}
  