@import "../../components/scss/tables.scss";
@import "../../components/scss/tabs.scss";
@import "../../components/scss/textareas.scss";

.flex {
  display: flex;
}

.typeContent {
  width: 310px;
  margin-top: 25px;
  &:first-child {
    margin-right: 50px;
  }
}
.flexContent {
  display: flex;
  flex-wrap: wrap;
}

.leaderItem {
  margin-top: 25px;
  max-width: 360px;
  &:first-child {
    margin-right: 50px;
  }
}

.cvTitle {
  > h1 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #232323;
  }
}

.modalWrap {
  background: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

.modal {
  background: white;
  border: 1px solid black;
  position: fixed;
  z-index: 5;
  margin: 0% auto;
  top: 0;
  width: 60%;
  padding: 16px;
  left: 0;
  right: 0;
}