.rootContainer {
  width: 80%;

  @media (min-width: 1500px) {
    position: absolute;
    right: 15%;
  }
  @media (max-width: 768px) {
    margin: 0 auto;
    padding-bottom: 50px;
  }
  @media (max-width: 630px) {
    width: 100% !important;
  }
}

.formContainer {
  position: relative;
  height: 224px;
  border: 1px solid rgba(202, 229, 238, 0.5);
  padding: 30px 30px 30px 30px;
  margin-bottom: 30px;
  transform: translateX(1000px);
  @media (max-width: 768px) {
    transform: none;
  }
}

.formContent {
  position: relative;
  height: 100%;
}

.formSubmitBtn {
  // margin: 0 0 0 auto;
  transform: translateY(500px);
}

.formTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px !important;
  text-align: left;
  transform: translateY(215px);
  line-height: 28px;
  color: rgba(237, 248, 252, 0.9);
  margin: 0 0 10px 0;
  @media (max-width: 768px) {
    font-size: 22px;
    transform: none;
  }
}

.input {
  display: block;

  height: 44px;
  &:first-child,
  &:last-child {
    padding: 5px 0;
  }
  padding: 25px 0;
  width: 100%;
  // todo: move to reset.css
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(202, 229, 238, 0.2);
  color: rgba(237, 248, 252, 1);
  box-sizing: border-box;
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px rgb(12,11,11) inset;
    -webkit-text-fill-color: rgba(237, 248, 252, 1);
    border-bottom: 1px solid #323535;
  }
}

.inputLast {
  height: 100px;
  position: absolute;
  bottom: 0;
  resize: none;
}

.inputLast::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.inputLast::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(103, 115, 119, 0.4);
}

.input,
input::placeholder,
textarea::placeholder {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
  color: #ffffff;
}

.modalRes {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(12, 11, 0, 0.9);
  margin: -30px;
  z-index: 5;
  align-items: center;
  justify-content: center;
  display: none;
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: auto;
    text-transform: uppercase;
    color: #0500ff;
  }
}
