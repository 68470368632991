.title {
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  width: 100%;
  position: absolute;
  margin-top: -18px;
  font-family: Bebas Neue, serif;
  font-style: normal;
  font-weight: 350;
  font-size: 50px;
  line-height: 50px;
  margin-top: -3px;

  > span {
    font-family: Bebas Neue, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 50px;
  }
  
  @media (max-width: 768px) {
    margin-top: 0;
  }
}

.aboutUsLetterAnim {
  position: absolute;
  right: 0;
  top: 25;
  z-index: -1;
  width: calc((100vw - (551px + 132px) + 262px) / 2);
  height: 100%;
  
  @media (max-width: 980px) {
    width: calc((100vw - (260px + 132px) + 262px) / 2);
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.aboutUsLetterAnimContent {
  position: absolute;
  transform: translateX(calc((100vw - (551px + 132px) - 262px) / 2));
  @media (max-width: 980px) {
    transform: translateX(calc((100vw - (260px + 132px) - 262px) / 2));
  }
  @media (max-width: 768px) {
    transform: translateX(calc((100vw - (30% + 15%) - 15%) / 2));
  }
  @media (max-width: 630px) {
    transform: translateX(calc((100vw - (30% + 20%) - 20%) / 2));
  }
  @media (max-width: 490px) {
    transform: translateX(calc((100vw - (30% + 25%) - 25%) / 2));
  }
}

.container {
  width: 262px;
  height: 239px;
  margin-top: -12px;
  opacity: 0;
  position: relative;
  @media (max-width: 768px) {
    margin: 0 auto;
    width: 100%;
    height: auto;
    opacity: 1;
  }
}

.title span {
  display: inline-block;
  line-height: 1em;
}
