.root {
  h2 {
    color: black;
    margin-bottom: 16px;
  }
}
.content{
    display: flex;
    margin-top: 70px;
}
.fields{
    width: 360px;
    &:last-child{
        margin-left:125px;
    }
}

.flex {
    display: flex;
  }
  
  .typeContent {
    width: 310px;
    margin-top: 25px;
    &:first-child {
      margin-right: 50px;
    }
  }
  .flexContent {
    display: flex;
    flex-wrap: wrap;
  }

