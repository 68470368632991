.table {
    border-collapse: collapse;
    margin-right: 0;
    width: 100%;
  
    th,
    td {
      color: #232323;
      text-align: start;
    }
  
    th {
      &:first-child {
        width: 15%;
        border-right: 1px solid rgba(35, 35, 35, 0.2);
      }
      &:last-child {
        padding-left: 30px;
      }
    }
    tr:first-child {
      border-bottom: 1px solid rgba(35, 35, 35, 0.2);
    }
    td:first-child {
      width: 100%;
      min-width: 240px;
      display: flex;
      flex-direction: column;
      
      .textTab {
        font-size: 14px;
        line-height: 30px;
        text-decoration-line: underline;
        color: #0500ff;
        cursor: pointer;
        outline: none;
        border-radius: 0;
        margin: 4px;
      }

      .textTabHighlighted {
         h4 {
          color: white!important;
         }
        background-color:  rgba(5, 0, 255, 0.3);
      }

    }
    td.fields {
      padding-left: 30px;
      width: 100%;
      border-left: 1px solid rgba(35, 35, 35, 0.2);
    }
  }


  .actionBlockBottom {
    // display: inline;
    >button {
      margin-right: 20px;
    }
  }

  .actionBlock{
    text-align: right;
    padding: 10px;
    img {
      cursor: pointer;
    }
  }

  .tab {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    *{
      color: black;
    }
    img {
      cursor: pointer;
    }
  }