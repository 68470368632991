.btn {
  width: 200px;
  height: 50px;
  background: #0500ff;
  outline: none;
  border: none;
  display: block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background: #0400cf;
  }

  margin-top: 30px;
  @media (max-width: 768px) {
    position: static;
    transform: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.right {
  margin-left: auto;
  margin-right: 0;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
