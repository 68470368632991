.slideItem {
  color: white;
  margin-left: 10%;
  @media (max-width: 768px) {
    margin-left: 0;
  }
}

.shape {
  width: 50%;
  height: 70vh;
  -webkit-shape-outside: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 100% 100%);
  float: right;
  @media (max-width: 768px) {
    display: none;
  }
}

.description {
  margin-top: 30px;
  font-family: arial;
  // @media (max-width: 768px) {
  //   display: none;
  // }
  > p {
    // width: 80%;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #edf8fc;
    margin-right: 0;
    @media (max-width: 768px) {
      width: 100% !important;
    }
  }
}

.head {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  > img {
    margin-right: 40px;
    width: 134px;
    height: 119px;
    @media (max-width: 768px) {
      margin: 0;
    }
  }
  > div {
    display: flex;
    flex-direction: column;
    @media (max-width: 768px) {
      width: 95%;
      margin: 0 auto;
      text-align: center;
    }
    > h2 {
      margin: 0;
      margin-bottom: 5px;
      font-family: Roboto;
      font-style: normal;
      font-size: 50px;
      max-width: 300px;
      line-height: 45px;
      color: #edf8fc;
      @media (max-width: 1100px) {
        font-size: 40px;
      }
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    > h3 {
      margin: 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      max-width: 350px;
      line-height: 30px;
      color: #edf8fc;
      @media (max-width: 768px) {
        max-width: 100%;
      }
    }
    > p {
      color: #0500ff;
      text-align: end;
    }
  }
}
