.rootContainer {
  width: 430px;
  padding-left: 40px;
  margin-top: 130px;

  @media (max-width: 1240px) {
    margin-right: 100px;
  }

  @media (max-width: 1100px) {
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    padding: 0;
    margin: 0 auto;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0 auto;
  }
}

.formContainer {
  border: 1px solid rgba(237, 248, 252, 0.5);
  padding: 30px;
  margin-bottom: 30.54px;
}

.formContent {
  position: relative;
  height: 100%;
}

.formTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  color: #edf8fc;
  text-align: center;
  margin-top: 0;
}

.input {
  display: block;
  height: 44px;
  padding: 6px 0;
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid rgba(237, 248, 252, 0.2);
  box-sizing: border-box;
  margin-bottom: 15px;
  &:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #040404 inset;
    -webkit-text-fill-color: #fff;
    border-bottom: 1px solid #323535;
  }
}

.inputLast {
  margin-top: 20px;
  margin-bottom: 10px;
  height: 100px;
  resize: none;
}

.inputLast::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.inputLast::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: rgba(103, 115, 119, 0.4);
}

.input,
input::placeholder,
textarea::placeholder {
  font-family: Roboto;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  border-radius: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
}
.dropdown {
  position: relative;
}

.dropdown::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 20px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

select option {
  background: black;
  color: #edf8fc;
  padding-left: 10px;
}

.modalRes {
  position: absolute;
  width: 100%;
  height: calc(100% - 20px);
  background: rgba(12, 11, 0, 0.9);
  margin: -30px;
  z-index: 5;
  align-items: center;
  justify-content: center;
  display: none;
  padding: 45px 30px;
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    margin: auto;
    text-transform: uppercase;
    color: #0500ff;
  }
}
