.root {
}

.letterAnim {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
}

.letterTop,
.letterBottom {
  will-change: transform;
}

.title {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  top: calc(10% + 55px);
  left: 23%;
  width: 167px;
  height: 100px;
  z-index: 2;
  @media (max-width: 630px) {
    width: auto;
    order: 1;
    bottom: 10px;
    top: auto;
    left: 72%;
  }
}

.title > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  height: 45px;
  color: #ffffff;
  will-change: transform;
  display: block;
}
