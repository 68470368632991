@import "../../components/scss/tables.scss";
@import "../../components/scss/textareas.scss";

.flex {
  display: flex;
}
.uploads{
  >div{
    width: 50%;
    margin: 0 auto;
  }
}
.typeContent {
  width: 310px;
  margin-top: 25px;
  &:first-child {
    margin-right: 70px;
  }
}
.flexContent {
  display: flex;
  flex-wrap: wrap;
}
.leaderItem {
  margin-top: 25px;
  max-width: 360px;
  &:first-child {
    margin-right: 50px;
  }
}

.textTabSelected {
  font-size: 20px;
}

.modalWrap {
  background: black;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
}

.modal {
  background: white;
  border: 1px solid black;
  position: fixed;
  z-index: 5;
  margin: 10% auto;
  top: 0;
  width: 60%;
  padding: 16px;
  left: 0;
  right: 0;
}
