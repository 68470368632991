.headerRoot {
  background-color: #040404;
  display: flex;
  align-items: center;
  height: 60px;
  // position: relative;
  padding: 0 10%;
  will-change: transform;
  z-index: 5;
  box-sizing: border-box;
  justify-content: space-between;
  transform: translateY(-100%);
  @media (max-width: 1024px) {
    padding: 0 5%;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
    box-shadow: 0 5px 5px -5px rgba(202, 229, 238, 0.3);

    height: auto;
    padding: 10px 0;
    transform: translateY(0);

    > a {
      padding-left: 50px;
      @media (max-width: 425px) {
        padding-left: 20px;
      }
    }
  }
}

.headerVisible {
  transform: translateY(0);
}

@media (min-width: 769px) {
  .imgMobile {
    display: none;
  }
}

.menuMobile {
  margin: 0 auto;
  padding-right: 95px;
  @media (max-width: 425px) {
    padding-right: 65px;
  }
}
.menu {
  position: absolute;
  top: 0;
  width: 100%;
  background: #000;
  > div > button {
    @media (max-width: 768px) {
      padding-right: 10%;
      padding-left: 10%;
    }
    @media (max-width: 425px) {
      padding-right: 0;
      padding-left: 0;
    }
    box-shadow: 0 -5px 5px -5px rgba(202, 229, 238, 0.3);
    border: none;
  }
  > div > button > span {
    margin-right: 60px;
  }
  > div > button > p {
    font-size: 18px;
    margin-left: 60px;
  }
  > div > div > ul {
    margin-left: 80px;
    @media (max-width: 768px) {
      padding-left: 10%;
    }
    @media (max-width: 425px) {
      padding-left: 0;
    }
  }
  > div > div > ul > li {
    border: none;
  }
  > div > div > ul > li:last-child {
    padding-bottom: 15px;
  }
  > div > div > ul > li > a {
    width: 100%;
    color: #a7a7a7;
    font-size: 18px;
  }
  > div {
    > img {
      display: block;
      margin: 20px auto;
    }
  }
  .lang {
    width: 100%;
    box-shadow: 0 -5px 5px -5px rgba(202, 229, 238, 0.3);
    border: none;
    > div {
      width: 30%;
      margin: 0 auto;
      padding: 20px 0;
      display: flex;

      justify-content: space-between;
    }
    > div > div > button {
      font-size: 16px;
    }
  }
}

a.noArrow {
  > div > button {
    @media (max-width: 768px) {
      padding-right: 10%;
      padding-left: 10%;
    }
    @media (max-width: 425px) {
      padding-right: 0;
      padding-left: 0;
    }
    box-shadow: 0 -5px 5px -5px rgba(202, 229, 238, 0.3);
    border: none;
  }
  > div > button > p {
    font-size: 18px;
    margin-left: 60px;
  }
  > div > button > span {
    display: none;
  }
}
.translation {
  display: flex;
  justify-content: space-between;
  > div {
    &:first-child {
      margin-right: 20px;
    }
    cursor: pointer;
    > button {
      color: #a7a7a7;
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.mobileTranslate {
  border: none !important;
  background: none !important;
}
