
body {
  margin: 0;
  padding: 0;
  background-color: #0c0b0b;
}

.app{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex: 1 1;
  /* min-height: 100vh; */
}

a, li{
  text-decoration: none;
  text-transform: none;
  list-style: none;
}
