.root {
  // justify-content: flex-end;
  padding: 100px 247px 150px 200px;
  position: relative;
  overflow: hidden;
  @media (max-width: 1250px) {
    padding: 100px 150px 150px 100px;
  }
  @media (max-width: 1024px) {
    padding: 180px 5% 150px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px 100px 20px;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    padding: 0;
  }
}
.mainContent {
  display: flex;
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 200px;
  }
  @media (max-width: 425px) {
    margin-top: 100px;
  }
}
.title {
  width: 300px;
  height: 300px;
  margin-right: 130px;
  @media (min-width: 1400px) {
    margin-right: 160px;
    > div {
      margin-left: 40px;
      width: 100%;
    }
  }
  @media (max-width: 1250px) {
    margin-right: 100px;
  }
  @media (max-width: 1100px) {
    margin-right: 30px;
  }
}

.leftImg {
  position: absolute;
  padding: 0;
  left: 0;
  bottom: 0;
  @media (max-width: 630px) {
    display: none;
  }
}

.letterC {
  display: block;
  margin: -135px 100px auto;
  @media (min-width: 769px) {
    display: none;
  }
  @media (max-width: 769px) {
    margin: -135px 150px auto;
  }
  @media (max-width: 425px) {
    margin: -135px 10px auto;
  }
}

.downloads {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 100px 0 0;
  @media (min-width: 1500px) {
    margin: 200px 0 0;
  }
  @media (max-width: 768px) {
    margin-top: 50px;
  }
  @media (max-width: 425px) {
    margin: 50px 0 100px;
  }
  > h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #edf8fc;
    text-align: center;
    width: 700px;
    margin-bottom: 30px;
    @media (max-width: 768px) {
      width: 60%;
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
    @media (max-width: 425px) {
      width: 100%;
      font-size: 20px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
  > div.downloadsButtons {
    width: 630px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    > a {
      border: none;
      padding: 0;
      background: none;
      cursor: pointer;
      outline: none;
      @media (max-width: 425px) {
        width: 100%;
      }

      img {
        @media (max-width: 425px) {
          width: 100%;
        }
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      flex-direction: column;
    }
  }
}

.contactUsMobileImg {
  display: none;
  position: absolute;

  @media (max-width: 768px) {
    display: block;
  }
}
