.root {
  
}

.title{
    position: absolute;
    display: block;
    top: 315px;
    width: 167px;
    height: 100px;
    text-align: left;
    left: 295px;
    font-family: Bebas Neue;
    font-style: normal;
    font-weight: 300;
    font-size: 50px;
    line-height: 50px;
    color: #FFFFFF;
    will-change: transform;
}

