.root {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  > h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #5a5a5a;
    margin-right: 20px;
  }
  >img{
    cursor: pointer;
  }
  > .edit {
    margin-right: 10px;
  }
}
