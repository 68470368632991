@import "../../../../node_modules/react-tabs/style/react-tabs.scss";

.buttonTabs {
    width: 100%;
    margin-bottom: 50px;
    border-bottom: 1px solid rgba(35, 35, 35, 0.5);
    > .buttons > [class*=" react-tabs__tab--selected"] {
      background: #0c0b0b;
      color: #fff;
    }
    > .buttons > li {
      font-family: Roboto;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: #0c0b0b;
      border: 1px solid #0c0b0b;
      border-radius: 0;
      background: #eeeeee;
      padding: 8px 30px;
      min-width: 145px;
      cursor: pointer;
      outline: none;
      text-align: center;
    }
  }
  ul[class*="react-tabs__tab-list"] {
    border: none;
  }