.root {
  width: 264px;
  height: 220.8px;

  @media (max-width: 1300px) {
    width: 200px;
  }

  @media (max-width: 1140px) {
    width: 150px;
  }

  @media (max-width: 1024px) {
    width: 20px;
  }
}

.title {
  position: absolute;
  right: 12%;
  top: 290px;
  width: 167px;
  height: 100px;
  z-index: 2;

  @media (max-width: 1300px) {
    right: 10%;
  }

  @media (max-width: 1140px) {
    right: 7%;
  }

  @media (max-width: 1024px) {
    right: 5%;
  }

  @media (max-width: 1024px) {
    right: 2%;
  }

  @media (max-width: 768px) {
    display: none;
  }
}

.title > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 350;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: inline-block;
}

.letterAnim {
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: absolute;

  @media (max-width: 768px) {
    display: none;
  }
}

.letterTop,
.letterBottom {
  will-change: transform;
}

.letterPos {
  transform: translateX(70%) translateY(10%);
  @media (max-width: 930px) {
    transform: translateX(65%) translateY(10%);
  }
  @media (max-width: 768px) {
    transform: translateX(0);
  }
}

.letterMobile {
  display: none;
  position: absolute;

  @media (max-width: 768px) {
    display: block;
    left: 33%;
    top: 50px;
  }

  @media (max-width: 630px) {
    left: 30%;
  }

  @media (max-width: 525px) {
    left: 25%;
  }

  @media (max-width: 425px) {
    left: 20%;
  }

  @media (max-width: 375px) {
    left: 15%;
  }

  @media (max-width: 325px) {
    left: 7%;
  }
}

.titleMobile {
  position: absolute;
  top: 125px;
  left: 115px;

  @media (max-width: 425px) {
    left: 85px;
  }
}

.titleMobile > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: inline-block;
}
