// @TODO make single button component
.root {
  padding: 10px 15px;
  margin-top: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 105px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.primary {
  background: #00ce2d;
}

.danger {
  background: #ff5151;
}

.disabled {
  pointer-events: none;
  filter: grayscale(50%);
  opacity: 0.5;
}

.add {
  background: #0500ff;
  padding: 10px 15px;
  margin-top: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 117px;
  &:disabled {
    opacity: 0.5;
    filter: grayscale(50%);
    pointer-events: none;
  }
}
