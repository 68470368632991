.title {
  position: absolute;
  display: block;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  text-align: center;
  color: #ffffff;
  will-change: transform;
  margin: -23px 0px 0px 55%;
  @media (max-width: 1024px) {
    margin: -23px 0px 0px 20%;
  }
  @media (max-width: 768px) {
    top: 180px;
    margin-left: 100px;
  }
  @media (max-width: 630px) {
    top: 130px;
    margin-left: 100px;
  }
}

.letter {
  position: absolute;
  // right: 0;
  right: -35%;
  top: -90px;
  z-index: -1;
  height: 100vh;
  width: 100%;
  @media (max-width: 1024px) {
    right: -10%;
  }
  @media (max-width: 768px) {
    right: 0;
  }
  @media (max-width: 630px) {
    display: none;
  }
}

.letterContainer {
  transform: translateY(90px);
}

.portfolioLetterGroup1,
.portfolioLetterGroup2 {
  will-change: transform;
}

.letterMobile {
  display: none;
  position: relative;
}

.titleMobile {
  position: absolute;
  top: 130px;
  left: -20px;
  z-index: 5;
}

.letterMobileImg {
  position: absolute;
  left: -40px;
}
