
.title {
  position: absolute;
  left: 50%;
  top: calc(10% + 130px);
  width: 167px;
  height: 100px;
  z-index: 2;
  @media (max-width: 1024px) {
    left: 35%;
  }
  @media (max-width: 768px) {
    top: 130px;
    left: 50px;
  }
}

.title > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: inline-block;
}

.letterAnim {
  // это нужно чтобы элементы буквы не обрезались контейнером
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0;
  position: absolute;
}


.letterTop,
.letterBottom {
  will-change: transform;
}

.letterPos {
  transform: translateX(40%) translateY(10%);
  @media (max-width: 1024px) {
    transform: translateX(15%) translateY(10%);
  }
  @media (max-width: 768px) {
    transform: translateX(0%) translateY(0%);
  }
}
