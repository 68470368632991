.root {
    >input{
        width: 80px;
    }
  > label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #828282;
  }
  
  .fileInput::-webkit-file-upload-button {
    visibility: hidden;
  }
  .fileInput {
    outline: none;
    padding: 17px 5px 0 0px;
    width: 90px;
    vertical-align: middle;
  }
  .fileInput::before {
    content: "Upload";
    padding: 8px 23px 9px 23px;
    background: #F4F4F4;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    border: 1px solid #757575;
    line-height: 16px;
    color: #0c0b0b;
    cursor: pointer;
  }
}
