.container {
  max-width: 100%;
  padding: 0;
  background-color: #040404;
  color: white;
  z-index: 5;

  hr {
    border: none;
    color: #232322;
    background-color: #232322;
    height: 1px;
  }

  .lowerText {
    position: relative;
    padding: 6px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    color: red;
    display: flex;
    justify-content: center;
    color: white;
    // width: 1200px;

    width: 100%;
    > p {
      text-align: center;
      font-weight: 300;
      font-size: 12px;
    }

    .rightsLinks {
      margin-left: 350px;
      position: absolute;
      @media (max-width: 980px) {
        margin-left: 270px;
      }
      @media (max-width: 768px) {
        display: none;
      }
    }

    .rightsLinks a {
      cursor: pointer;
      color: white;
      margin-left: 15px;
    }
  }
}

.footerRoot {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 40px 0 10px;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  img {
    padding-left: 0;
  }
  p {
    display: flex;
    align-items: center;
  }
  a,
  p,
  div.info {
    cursor: pointer;
    text-decoration: none;
    color: white;

    > div {
      min-width: 30px;
      width: 30px;
      > img {
        // width: 15px;
        height: 15px;
        margin: 0 auto;
        display: block;

        @media (max-width: 768px) {
          width: auto;
          height: 20px;
        }
      }
    }
  }
  div.info {
    display: flex;
    align-items: center;
  }
}

.logoFooter {
  display: block;
  margin: 0 auto;
}

.socialLogos img {
  margin: 10px 10px 0 10px;
  &:hover {
    filter: brightness(1000%);
  }
}
.socialLogos {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 1024px) {
  .socialLogos {
    display: flex;
    justify-content: center;
    > a > img {
      height: 20px !important;
      width: auto !important;
      filter: brightness(1000%);
    }
  }
}

.logoRefs {
  margin-right: 149px;
  @media (max-width: 1024px) {
    margin-right: 0;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.footerContent {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1024px) {
    width: 90%;
    margin: 0 5%;
    margin-top: 30px;
  }
  @media (max-width: 768px) {
    display: none;
  }
  .footerItem {
    display: flex;
    flex-direction: column;
    width: 230px;
    line-height: 30px;
    // &:nth-child(2){
    //   text-align: center;
    // }
    // &:last-child{
    //   text-align: end;
    // }
    > a,
    > p,
    > div {
      font-family: Roboto;
      font-style: normal;
      font-weight: 300;
    }
    h2 {
      margin: 0 0 10px;
      text-transform: uppercase;
    }
  }
}

.menuDropdown {
  > div > button {
    padding: 20px 0;
    @media (max-width: 768px) {
      padding: 20px 10%;
    }
    @media (max-width: 425px) {
      padding: 20px 0;
    }
    > p {
      text-transform: uppercase;
    }
    > span {
      margin-right: 50px;
    }
  }
  > div > div > ul {
    margin-left: 80px;
    @media (max-width: 768px) {
      margin-left: calc(10% + 80px);
    }
    @media (max-width: 425px) {
      margin-left: 80px;
    }
  }
  > div:last-child > div {
    border-bottom: 1px solid rgba(202, 229, 238, 0.2);
  }
  > div:last-child > div > ul {
    margin-left: calc(50px + 10%);
    @media (max-width: 425px) {
      margin-left: 50px;
    }
    > li {
      padding-left: 0;
      padding-bottom: 10px;
      &:first-child {
        padding-top: 5px;
      }
      &:last-child {
        padding-bottom: 25px;
      }
    }
  }
  > div > div > ul > li {
    border-top: none;
    justify-content: flex-start;
    padding-left: 0;
    padding-bottom: 25px;
    &:first-child {
      padding-top: 5px;
    }
    &:last-child {
      padding-bottom: 25px;
    }
    > img {
      padding-right: 10px;
      width: 15px;
    }
  }
  @media (min-width: 769px) {
    display: none;
  }
}

.links {
  display: flex;
  justify-content: space-around;
  padding: 40px 50px;
  @media (max-width: 768px) {
    justify-content: space-between;
    padding: 40px calc(10% + 50px);
    > a > img {
      margin: 0 auto;
    }
  }
  @media (max-width: 425px) {
    display: flex;
    justify-content: space-around;
    padding: 40px 50px;
  }
  @media (min-width: 769px) {
    display: none;
  }
}
.mainLogoMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0 calc(10% + 50px) 40px;
  }
  @media (max-width: 425px) {
    padding: 0 50px 40px 50px;
  }
  > div {
    display: flex;
    flex-direction: column;
    text-align: end;
    > a {
      text-decoration: underline;
      font-size: 16px;
      font-weight: 300;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
  @media (min-width: 769px) {
    display: none;
  }
}
