
@font-face {
  font-family: 'Bebas Neue';
  src: url('./assets/fonts/BebasNeueLight.otf');
  src: local('Bebas Neue Thin'), local('BebasNeue-Thin'),
  url('./assets/fonts/BebasNeueLight.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/BebasNeueLight.woff') format('woff'),
  url('./assets/fonts/BebasNeueLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('./assets/fonts/BebasNeueBook.otf');
  src: local('Bebas Neue Book'), local('BebasNeueBook'),
  url('./assets/fonts/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/BebasNeueBook.woff') format('woff'),
  url('./assets/fonts/BebasNeueBook.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gotham Pro';
  src: url('./assets/fonts/GothamPro.eot');
  src: local('Gotham Pro'), local('GothamPro'),
  url('./assets/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/GothamPro.woff') format('woff'),
  url('./assets/fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ISOCT2';
  src: url('./assets/fonts/ISOCT2.ttf');
  src: local('ISOCT2'), local('ISOCT2'),
  url('./assets/fonts/ISOCT2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Robotolight.ttf');
  src: local('Roboto light'), local('Robotolight'),
  url('./assets/fonts/Robotolight.woff') format('woff'),
  url('./assets/fonts/Robotolight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Robotothin.ttf');
  src: local('Roboto thin'), local('Robotothin'),
  url('./assets/fonts/Robotothin.woff') format('woff'),
  url('./assets/fonts/Robotothin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

