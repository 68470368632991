* {
  color: #fff;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  padding: 0;
}
.root {
}
.flex {
  display: flex;
}
.content {
  background: #fff;
  width: calc(100% - 265px);
  min-height: calc(100vh - 75px);
  padding: 25px 0 50px 50px;
}
.loginForm {
  > label {
    color: black;
  }
  > input {
    min-width: 250px;
    min-height: 40px;
    color: black;
    outline: none;
    border: 1px solid black;
    border-radius: 5px;
    font-size: 18px;
    margin-bottom: 20px;
  }
  > button {
    color: white;
    background: black;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1 px solid white;
    outline: none;
  }
}
