.largeTextarea {
  width: 310px;
  height: 100px;
  resize: none;
  outline: none;
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #757575;
  padding: 10px;
}
.slimTextarea {
  width: 360px;
  height: 60px;
  resize: none;
  outline: none;
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #757575;
  padding: 10px;
}
h3.subTitle, p.subTitle {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
  margin-left: 15px;
  margin-top: 7px;
  display: inline-block;
}
span.addNew {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #0500ff;
  display: inline-block;
  float: right;
  margin-top: 7px;
  cursor: pointer;
}
.smTextarea {
  width: 360px;
  height: 50px;
  resize: none;
  outline: none;
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #757575;
  padding: 10px;
}
div.addTextarea {
  position: relative;
}
.addition {
  width: 320px;
  height: 50px;
  resize: none;
  outline: none;
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #757575;
  padding: 10px;
  margin-top: 10px;
  ~ img {
    position: absolute;
    top: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
}
.additionSmall {
  width: 320px;
  height: 32px;
  resize: none;
  outline: none;
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #757575;
  padding: 0 10px;
  margin-top: 10px;
  ~ img {
    position: absolute;
    top: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
}

.input {
  width: 360px;
  height: 32px;
  resize: none;
  outline: none;
  color: #5a5a5a;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border: 1px solid #757575;
  padding: 0 10px;
}
