#logo-part-1{ 
    transform: translate3d(500px, -400px, 0);
}

#logo-part-2{ 
    transform: translate3d(500px, -200px, 0);
}
#logo-part-3{ 
    transform: translate3d(500px, 0, 0);
}
#logo-part-4{ 
    transform: translate3d(500px, 200px, 0);
}
#logo-part-5{ 
    transform: translate3d(500px, 400px, 0);
}

#logo-part-6{ 
    transform: translate3d(1000px, -800px, 0);
}
#logo-part-7{ 
    transform: translate3d(1000px, -400px, 0);
}
#logo-part-8{ 
    transform: translate3d(1000px, 0, 0);
}
#logo-part-9{ 
    transform: translate3d(1000px, 400px, 0);
}
#logo-part-10{ 
    transform: translate3d(100px, 800px, 0);
}

#logo-part-1-b{ 
    transform: translate3d(-500px, -400px, 0);
}

#logo-part-2-b{ 
    transform: translate3d(-500px, -200px, 0);
}
#logo-part-3-b{ 
    transform: translate3d(-500px, 0, 0);
}
#logo-part-4-b{ 
    transform: translate3d(-500px, 200px, 0);
}
#logo-part-5-b{ 
    transform: translate3d(-500px, 400px, 0);
}

#logo-part-6-b{ 
    transform: translate3d(-1000px, -800px, 0);
}
#logo-part-7-b{ 
    transform: translate3d(-1000px, -400px, 0);
}
#logo-part-8-b{ 
    transform: translate3d(-1000px, 0, 0);
}
#logo-part-9-b{ 
    transform: translate3d(-1000px, 400px, 0);
}
#logo-part-10-b{ 
    transform: translate3d(-1000px, 800px, 0);
}


.origin {
    transform: translate3d(0, 0, 0)!important;
}

.change {
    will-change: transform;
}