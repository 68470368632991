.root {
  width: 264px;
  height: 220.8px;

  @media (max-width: 1300px) {
    width: 200px;
  }

  @media (max-width: 1140px) {
    width: 150px;
  }

  @media (max-width: 1024px) {
    width: 50px;
  }

  @media (max-width: 960px) {
    width: 20px;
  }
}

.title {
  position: absolute;
  right: 14%;
  top: 250px;
  width: 167px;
  height: 100px;
  z-index: 2;

  @media (max-width: 1300px) {
    right: 9%;
  }

  @media (max-width: 1140px) {
    right: 6%;
  }

  @media (max-width: 1024px) {
    right: 3%;
  }

  @media (max-width: 960px) {
    right: 1%;
  }

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-height: 740px) {
    top: 100px;
  }
}

.title > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: inline-block;
}

.letterAnim {
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: absolute;

  @media (max-width: 1300px) {
    left: 20px;
  }

  @media (max-width: 1024px) {
    left: 50px;
  }

  @media (max-width: 768px) {
    display: none;
  }
  @media (max-height: 740px) {
    top: -115px;
  }
}

.letterTop,
.letterBottom {
  will-change: transform;
}

.letterPos {
  transform: translateX(70%) translateY(130px);

  @media (max-width: 768px) {
    transform: translateX(0);
  }
}

.letterMobile {
  display: none;
  position: absolute;

  @media (max-width: 768px) {
    display: block;
    left: 42%;
    top: 50px;
  }

  @media (max-width: 630px) {
    left: 39%;
  }

  @media (max-width: 475px) {
    left: 35%;
  }

  @media (max-width: 375px) {
    left: 30%;
  }

  @media (max-width: 325px) {
    left: 25%;
  }
}

.titleMobile {
  position: absolute;
  top: 50px;
  left: 55px;
}

.titleMobile > span {
  text-align: left;
  font-family: Bebas Neue;
  font-style: normal;
  font-weight: 300;
  font-size: 50px;
  line-height: 50px;
  color: #ffffff;
  will-change: transform;
  display: inline-block;
}
