.root {
  display: flex;
  overflow: hidden;
  position: relative;
  min-height: 60vh;
  &:after {
    content: "";
    position: absolute;
    width: 1100%;
    height: 1100%;
    right: -500%;
    background: linear-gradient(245.27deg, #0e0d0d -2.34%, #0f0f0f 81.2%);
    transform-origin: 50.2% 0;
    transform: rotate(60deg) translateY(100%);
    transition: all 3s ease-out;
    z-index: -1;
    @media (max-width: 980px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.animate {
  &:after {
    transform: rotate(60deg) translateY(0);
  }
}
.content {
  width: 720px;
  margin-left: 15%;
  margin-top: 60px;
  padding-bottom: 10%;
  @media (max-width: 1250px) {
    margin-left: 6%;
  }
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 980px) {
    margin-top: 5%;
  }
  @media (max-width: 630px) {
    order: 3;
    width: 100%;
    margin:0 auto;
    margin: 0 auto 5%;
    padding: 0 5%;
  }
  > p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    color: #edf8fc;
    @media (max-width: 630px) {
      width: 100%;
    }
  }
  > p:last-child,
  p:nth-last-child(2) {
    width: 665px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    @media (max-width: 630px) {
      width: 90%;
    }
  }
  > p:nth-last-child(3),
  p:nth-last-child(4) {
    width: 600px;
    @media (max-width: 1024px) {
      width: 95%;
    }
    @media (max-width: 630px) {
      width: 90%;
    }
  }
  > p:nth-last-child(5) {
    width: 577px;
    @media (max-width: 1024px) {
      width: 90%;
    }
    @media (max-width: 630px) {
      width: 90%;
    }
  }
  > p:nth-last-child(6) {
    width: 545px;
    @media (max-width: 1024px) {
      width: 85%;
    }
    @media (max-width: 630px) {
      width: 90%;
    }
  }
  > p:nth-last-child(7) {
    width: 484px;
    @media (max-width: 1024px) {
      width: 80%;
    }
    @media (max-width: 630px) {
      width: 90%;
    }
  }
}

.logoBlock {
  width: 40%;
  position: absolute;
  right: 0;
  height: 100%;
  @media (max-width: 630px) {
    min-width: 228px;
    min-height: 220px;
    position: relative;
    margin: 50px 15px 40px;
  }
}

.leftArr {
  position: absolute;
  top: 230px;
  left: 0;
  z-index: -1;
  @media (max-width: 630px) {
    display: none;
  }
}

.rigthArr {
  top: 115px;
  right: 0;
  position: absolute;
  z-index: 0;
  @media (max-width: 630px) {
    display: none;
  }
}
