.root {
  width: 190px;
  height: 166px;
  @media (max-width: 768px) {
    display: none;
  }
}

.mainLogoLetterAnim {
  width: 100vw;
  height: 100%;
  left: 0;
  top: 25px;
  position: absolute;
  @media (max-width: 980px) {
    top: 60px;
    left:auto;
  }
}

.mainLogoLetterGroup {
  transform: translateX(calc(50% - 174.5px)) translateY(20%) scale(2);
  will-change: transform;
  path {
    transition: transform 1s linear;
  }
  @media (max-width: 980px) {
    transform: none;
    will-change: none;
  }
}

.logoAnim {
  animation-name: test;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  @media (max-width: 980px) {
    animation-name: none;

  }
}

@keyframes test {
  0% {
    transform: translateX(calc(50% - 174.5px)) translateY(20%) scale(2);
  }
  100% {
    transform: translateX(calc((100vw - 658px) / 2 - 18px))
      translateY(calc(50% - 180px)) scale(1);
  }
}
